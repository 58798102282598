import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router-dom';

import {ROUTES} from '../../../constants/routes';
import {updateCheckedList} from '../../../store/settings';

import ColleagueItem from '../../ProfilePage/ColleagueList/ColleagueItem';
import Icon from '../../Common/Icon';
import {getColleaguesStatus} from '../../../api/member';
import AddPeopleSection from '../../Common/AddPeopleSection';
import {updateConfirmationCard} from '../../../store/cards';
import {confirmActionsMap} from '../../Cards/Common/ConfirmAction/confirmActions';
import {Typography} from '../../Common/Typography';

function AddPeople({showHideMore, showAddMoreButton = true, showRemoveButton = true, hasMSOutlook, type}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedStartTime = useSelector((state) => state.entitiesBooking.selectedStartTime);
	const selectedEndTime = useSelector((state) => state.entitiesBooking.selectedEndTime);
	const userData = useSelector((state) => state.auth.data.userData);
	const attendeeList = useSelector((state) => state.entitiesBooking.attendeeList);
	const filteredAttendeeList = attendeeList?.filter((item) => item.member?.id !== userData?.id && item.type === type);

	const [showMore, setShowMore] = useState(false);
	const [colleaguesStatus, setColleaguesStatus] = useState();

	const goToColleagueList = () => {
		console.log('goToColleagueList: ', attendeeList);
		dispatch(updateCheckedList(attendeeList));
		history.push(ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST, {addAttendee: true, type});
	};

	const removeItem = (e, removedAttendee) => {
		console.log('remove:', removedAttendee, removedAttendee);
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_ATTENDEE,
				{
					headerKey: 'confirmDelete.confirmDeleteAttendee.header',
					infoKey: 'confirmDelete.confirmDeleteAttendee.infoText',
				},
				removedAttendee,
			),
		);
	};

	useEffect(async () => {
		setColleaguesStatus();
		if (!filteredAttendeeList) return;
		if (
			!selectedStartTime ||
			!selectedEndTime ||
			filteredAttendeeList?.length === 0 ||
			filteredAttendeeList?.some((attendee) => attendee?.externalStatus)
		) {
			setColleaguesStatus([]);
			return;
		}
		const colleagues = filteredAttendeeList?.map((attendee) => attendee?._id);
		try {
			const response = await getColleaguesStatus(colleagues, {
				start: DateTime.fromISO(selectedStartTime).toUTC().toISO(),
				end: DateTime.fromISO(selectedEndTime).toUTC().toISO(),
			});
			setColleaguesStatus(response.data);
		} catch (error) {
			setColleaguesStatus([]);
			console.log(error);
		}
	}, [selectedStartTime, selectedEndTime, attendeeList]);

	function getStatusForThisColleague(colleagueId) {
		if (!colleaguesStatus || colleaguesStatus?.length === 0) return null;
		return colleaguesStatus.find((colleague) => colleague?.entity?._id === colleagueId);
	}

	const renderFilteredAttendeeList = () => {
		if (!filteredAttendeeList) {
			return <AddPeopleSection clickHandler={goToColleagueList} infoText={t('settings.privacyInformation.shareLocation.searchPeople')} />;
		}

		if (!showHideMore) {
			return (
				<div className="colleagues-container">
					{filteredAttendeeList?.map(
						(item, index) =>
							(!item?.type || type === item?.type) && (
								<>
									{console.log(item?.externalStatus)}
									<ColleagueItem
										listLength={filteredAttendeeList?.length}
										index={index}
										key={item._id}
										loadingColleagueStatus={!colleaguesStatus}
										colleagueStatus={getStatusForThisColleague(item._id)}
										data={item?.member ? item?.member : item}
										invitationResponse={item?.externalStatus}
										removeItem={(e) => {
											removeItem(e, item);
										}}
										showRemoveButton={showRemoveButton}
										hasRoomTypeMSOutlook={hasMSOutlook}
									/>
								</>
							),
					)}
					{showAddMoreButton && (
						<div className="add-more-button" onClick={goToColleagueList}>
							<div className="plus-sign">+</div>
							<p>{t('yourBooking.headers.addMorePeople')}</p>
						</div>
					)}
				</div>
			);
		}

		return (
			<>
				<div className={'list-wrapper list-wrapper-show-all' + (showMore ? ' list-wrapper-show-all-opened' : '')}>
					{filteredAttendeeList?.map(
						(item, index) =>
							(!item?.type || type === item?.type) && (
								<ColleagueItem
									listLength={filteredAttendeeList?.length}
									index={index}
									key={item._id}
									colleagueStatus={getStatusForThisColleague(item._id)}
									data={item?.member ? item?.member : item}
									enableCheck={false}
									checkedIn={item?.checkIn !== undefined}
									invitationResponse={item?.externalStatus}
									showRemoveButton={showRemoveButton}
									hasRoomTypeMSOutlook={hasMSOutlook}
									loadingColleagueStatus={!colleaguesStatus}
								/>
							),
					)}
				</div>
				{filteredAttendeeList?.length > 4 && (
					<p
						className="added-people-show-more"
						onClick={() => {
							setShowMore(!showMore);
						}}>
						{showMore ? (
							t('bookingPage.showLessColleagues')
						) : (
							<>
								{t('bookingPage.showMoreColleagues')}
								<Icon iconName={'angle-down'} cssClass={'angle-down icon-purple'}></Icon>
							</>
						)}
					</p>
				)}
			</>
		);
	};
	const getSectionContent = () => {
		switch (type) {
			case 'optional':
				return (
					<>
						<p className="title">{t('settings.privacyInformation.shareLocation.optionalColleaguesLabel')}</p>
						<p className="subtitle">{t('settings.privacyInformation.shareLocation.addOptionalColleagues')}</p>
					</>
				);
			case 'required':
				return (
					<>
						<p className="title">{t('settings.privacyInformation.shareLocation.requiredColleaguesLabel')}</p>
						<p className="subtitle">{t('settings.privacyInformation.shareLocation.addRequiredColleagues')}</p>
					</>
				);
			default:
				return <></>;
		}
	};
	const renderSectionWithContent = () => {
		const shouldRenderSection = showAddMoreButton || filteredAttendeeList?.some((item) => item.type === type);

		if (!shouldRenderSection) {
			return <></>;
		}
		return (
			<div className="add-people-section">
				{getSectionContent()}
				{renderFilteredAttendeeList()}
			</div>
		);
	};
	return <>{renderSectionWithContent()}</>;
}

export default AddPeople;
